import React, { useEffect, useRef, useState } from 'react'
import Moment from 'react-moment'
import { toast } from 'react-toastify'
import { Table } from 'reactstrap'
import serviceApi, { Api, Lang } from '../Config'

const SharedInfoKendaraan = () => {

  const mounted = useRef(true)

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const fetchData = () => {
    setLoading(true);
    serviceApi.defaults.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem(Api.Token))}`;
    serviceApi.get(`${Api.Kendaraan}/alert`)
      .then(res => {
        if (mounted.current) {
          setLoading(false)
          setData(res.data)
        }
      })
      .catch(err => {
        setLoading(false)
        toast.error(Lang.ErrorMsg)
        console.log(err)
      })
  }

  useEffect(() => {
    fetchData()

    return () => {
      mounted.current = false
    }
  }, [])

  if (loading) {
    return "Loading..."
  }

  if (data.length < 1) {
    return <p>Tidak ada pembayaran Pajak Kendaraan dalam 1 bulan ke depan</p>
  }

  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th className="text-center">No</th>
          <th>No. Polisi</th>
          <th>Merk</th>
          <th className="text-center">Tgl. STNK</th>
          <th className="text-center">Tgl. KIR</th>
        </tr>
      </thead>
      <tbody>
        {data.map((obj, i) => (
          <tr key={i}>
            <td className="text-center">{i + 1}</td>
            <td className="nowrap">{obj.nopol}</td>
            <td>{obj.merk}</td>
            <td className="text-center">
              {obj.tgl_stnk ? <Moment format="DD/MM/YYYY">{obj.tgl_stnk}</Moment> : false}
            </td>
            <td className="text-center">
              {obj.tgl_kir ? <Moment format="DD/MM/YYYY">{obj.tgl_kir}</Moment> : false}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default SharedInfoKendaraan
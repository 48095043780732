import React, { useContext } from 'react'
import { Helmet } from 'react-helmet';
import { AppContext } from '../../App';
import { RouteBasic } from '../../Config';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardCounter from './DashboardCounter';
import DashboardChartEkspedisiRekapPerMonth from './DashboardChartEkspedisiRekapPerMonth';
import DashboardChartPengeluaranRekapPerMonth from './DashboardChartPengeluaranRekapPerMonth';
import SharedInfoKendaraan from '../../shared/SharedInfoKendaraan';
import DashboardKomentarUnread from './DashboardKomentarUnread';
import WablasInfoDevice from './WablasInfoDevice';

const Dashboard = () => {

  const app = useContext(AppContext)

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${app.konfigurasi.nama_perusahaan} - ${RouteBasic[0].Label}`}</title>
      </Helmet>

      <DashboardCounter />

      <div className="mb-3">
        <WablasInfoDevice/>
      </div>

      <Row>
        <Col sm={6}>
          <Card className="mb-4">
            <CardHeader className="bg-secondary text-light">
              <FontAwesomeIcon icon="chart-line" fixedWidth className="mr-2" />Grafik Ekspedisi Per Bulan
            </CardHeader>
            <CardBody>
              <DashboardChartEkspedisiRekapPerMonth />
            </CardBody>
          </Card>
        </Col>
        <Col sm={6}>
          <Card className="mb-4">
            <CardHeader className="bg-secondary text-light">
              <FontAwesomeIcon icon="chart-bar" fixedWidth className="mr-2" />Grafik Pengeluaran Per Bulan
            </CardHeader>
            <CardBody>
              <DashboardChartPengeluaranRekapPerMonth />
            </CardBody>
          </Card>
        </Col>

        <Col sm={6}>
          <Card className="mb-4">
            <CardHeader className="bg-info text-light">
              <FontAwesomeIcon icon="comments" fixedWidth className="mr-2" />Alert Komentar belum dibaca
            </CardHeader>
            <CardBody>
              <DashboardKomentarUnread />
            </CardBody>
          </Card>
        </Col>
        <Col sm={6}>
          <Card className="mb-4">
            <CardHeader className="bg-danger text-light">
              <FontAwesomeIcon icon="bell" fixedWidth className="mr-2" />Alert Pembayaran Pajak Kendaraan
            </CardHeader>
            <CardBody>
              <SharedInfoKendaraan />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Dashboard;
import React from 'react'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ReSearch = ({
  placeholder = 'Cari...',
  handleSearch = (e) => {}
}) => {
  return (
    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <FontAwesomeIcon icon="search" fixedWidth />
        </InputGroupText>
      </InputGroupAddon>
      <Input
        type="search"
        onKeyPress={e => {
          if (e.key === 'Enter') {
            handleSearch(e.target.value);
          }
        }}
        placeholder={placeholder}
      />
    </InputGroup>
  )
}

export default ReSearch;
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Table, Button } from 'reactstrap';
import { useApp } from '../../App';
import serviceApi from '../../Config';

const WablasInfoDevice = () => {
  const { konfigurasi } = useApp();
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState({});

  const fetchData = () => {
    setLoading(true);
    serviceApi.get('/wablas/device/info').then(res => {
      setItem(res.data?.data);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
      toast.error(err?.response?.data);
    });
  }

  useEffect(() => fetchData(), []);

  return (
    <Card color="dark" inverse>
      <CardHeader>WhatsApp Info Device</CardHeader>
      <CardBody>
        {loading ? "Loading..." : 
          <React.Fragment>
            <Table responsive borderless hover className="text-primary">
              <tbody>
                <tr>
                  <th>Sender</th>
                  <th>:</th>
                  <td>{item?.sender}</td>
                </tr>
                <tr>
                  <th>Name</th>
                  <th>:</th>
                  <td>{item?.name}</td>
                </tr>
                <tr>
                  <th>Quota</th>
                  <th>:</th>
                  <td>{item?.quota}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <th>:</th>
                  <td>{item?.status}</td>
                </tr>
                <tr>
                  <th>Expired</th>
                  <th>:</th>
                  <td>{moment(item?.expired_date).format('DD/MM/YYYY')}</td>
                </tr>
              </tbody>
            </Table>

            <br />

            <Button type="button" color="primary"
              onClick={() => window.open(`https://solo.wablas.com/api/device/scan?token=${konfigurasi.token_msg}`, '_blank')}
            >
              Scan QR Code
            </Button>
          </React.Fragment>
        }
      </CardBody>
    </Card>
  )
}

export default WablasInfoDevice;
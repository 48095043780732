import React, { useState, useRef, useEffect } from 'react'
import serviceApi, { Api, Lang } from '../../Config'
import moment from 'moment'
import { toast } from 'react-toastify'
import ApexCharts from 'react-apexcharts'
import { Spinner, FormGroup, Label, Input } from 'reactstrap'

const DashboardChartEkspedisiRekapPerMonth = () => {

  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState({ tahun: moment().year() })
  const [series, setSeries] = useState([])
  const [options, setOptions] = useState({})

  const mounted = useRef(true)

  const fetchData = (tahun) => {
    setLoading(true)
    serviceApi.get(`${Api.Stats}/ekspedisi-rekap-per-month?tahun=${tahun}`)
      .then(res => {
        if (mounted.current) {
          setLoading(false)
          setFilter(prevState => ({...prevState, tahun: tahun}))

          const arrData = []
          const arrCategories = []
          res.data.forEach(obj => {
            arrData.push(obj.persentase);
            arrCategories.push(obj.short);
          });

          setSeries([
            {
              name: 'Persentase',
              data: arrData
            }
          ])

          setOptions({
            chart: {
              height: 350,
              type: 'line',
            },
            stroke: {
              width: 7,
              curve: 'smooth'
            },
            xaxis: {
              type: 'text',
              categories: arrCategories,
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                gradientToColors: [ '#FDD835'],
                shadeIntensity: 1,
                type: 'horizontal',
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100, 100, 100]
              },
            },
            markers: {
              size: 4,
              colors: ["#FFA41B"],
              strokeColors: "#fff",
              strokeWidth: 2,
              hover: {
                size: 7,
              }
            },
            yaxis: {
              min: 0,
              title: {
                text: 'Persentase',
              },
              labels: {
                show: true,
                formatter: function (val) {
                  return new Intl.NumberFormat('id-ID', {minimumFractionDigits: 0, maximumFractionDigits: 0}).format(val) + "%";
                }
              }
            }
          })
        }
      })
      .catch(err => {
        setLoading(false)
        toast.error(Lang.ErrorMsg)
        console.log(err)
      })
  }

  useEffect(() => {
    fetchData(moment().year());

    return () => {
      mounted.current = false;
    }
  }, [])

  if (loading) {
    return <Spinner color="primary" />
  }

  return (
    <React.Fragment>
      <FormGroup className="d-flex align-items-center">
        <Label className="mb-0 mr-3 nowrap">Tahun : </Label>
        <Input
          type="number"
          onChange={e => fetchData(Number(e.target.value))}
          value={filter.tahun || ''}
        />
      </FormGroup>

      <ApexCharts
        options={options}
        series={series}
        type="line"
      />
    </React.Fragment>
  )
}

export default DashboardChartEkspedisiRekapPerMonth;
import React, { useState, useContext } from 'react'
import {
  Collapse, Navbar, NavbarToggler, NavbarBrand, Nav,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Spinner, NavItem, NavbarText
} from 'reactstrap';
import { AppContext, fakeAuth } from '../../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReSearch from '../reusable/ReSearch';
import { NavLink as RNavLink } from 'react-router-dom';
import { UploadPath, RouteBasic } from '../../Config';

const SharedNavbar = ({ history }) => {

  const app = useContext(AppContext)

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar color="white" light expand="md" fixed="top" className="shadow-sm">
      <NavbarBrand>
        <div className="d-flex align-items-center">
          <Button type="button" color="secondary" outline 
            className="border-0"
            onClick={app.toggleSidebar}
          >
            <FontAwesomeIcon icon="bars" fixedWidth />
          </Button>

          <div className="ml-2 d-flex align-items-center">
            {app.userLogin.kode_kantor === 'FM-GLB' ?
              <img src={`${UploadPath}/gerai/${app.userLogin?.kantor?.logo}`} height={50} alt="FM-GLB" />
              :
              <img src={`${UploadPath}/${app.konfigurasi.logo_transparent}`} height={50} alt="logo"/>
            }
            {app.userLogin.kode_kantor !== 'FM-GLB' && (
              <div className="ml-2 d-flex flex-column">
                <b>{app.konfigurasi.nama_perusahaan || 'Loading...'}</b>
                <small className="text-muted" style={{fontSize: '0.6rem'}}>{`ManTri Kargo Syari'ah`}</small>
                <small className="text-muted" style={{fontSize: '0.6rem'}}>{`PT. Tri Mandiri Kargo`}</small>
              </div>
            )}
          </div>
        </div>
      </NavbarBrand>

      <NavbarToggler onClick={toggle} />

      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <ReSearch 
              placeholder="Nomor Resi..."
              handleSearch={e => {
                if (e) {
                  history.push(`${RouteBasic[1].URL}/${e}`);
                }
              }}
            />
          </NavItem>

          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              <FontAwesomeIcon icon="user" fixedWidth className="mr-2" />Hi, <b>{app.userLogin.firstname || <Spinner color="light" size="sm" />}</b>
            </DropdownToggle>
            <DropdownMenu right>
              {app.userLogin.id_level === 1 && 
                <DropdownItem tag={RNavLink} to="/konfigurasi">
                  Konfigurasi
                </DropdownItem>
              }
              {(app.userLogin.id_level === 1 || app.userLogin.id_level === 2) ? 
                <DropdownItem tag={RNavLink} to="/users/manage">
                  Manage User
                </DropdownItem> : false
              }
              <DropdownItem tag={RNavLink} to="/users/profile">
                My Profile
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem 
                onClick={() => {
                  fakeAuth.signout(() => history.push('/login'))
                }}
              >
                <FontAwesomeIcon icon="power-off" fixedWidth className="mr-2" color="red" />Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {app.userLogin.kode_kantor !== 'FM-GLB' && (
          <NavbarText>
            <img src={app.userLogin?.kantor?.logo ? `${UploadPath}/gerai/${app.userLogin?.kantor?.logo}` : `${UploadPath}/${app.konfigurasi.logo_2}`} height={50} alt="logo partner"/>
          </NavbarText>
        )}
      </Collapse>
    </Navbar>
  )
}

export default SharedNavbar;
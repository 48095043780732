import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Badge, Button, Table } from 'reactstrap'
import serviceApi, { Api, Lang, RouteBasic } from '../../Config'

const DashboardKomentarUnread = () => {

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const mounted = useRef(true)

  const fetchData = () => {
    setLoading(true)
    serviceApi.get(`${Api.EkspedisiKomentar}/unread`)
      .then(res => {
        if (mounted.current) {
          setData(res.data)
          setLoading(false)
        }
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    fetchData()

    return () => {
      mounted.current = false
    }
  }, [])

  if (loading) {
    return "Loading..."
  }

  if (data.length < 1) {
    return <p>Tidak ada komentar yang belum dibaca</p>
  }

  return (
    <React.Fragment>
      <div className="mb-3">
        <Button type="button" color={Lang.Refresh.Color} 
          onClick={() => fetchData()}
        >
          <FontAwesomeIcon icon={Lang.Refresh.Icon} fixedWidth className="mr-2" />{Lang.Refresh.Label}
        </Button>
      </div>

      <Table hover size="sm">
        <thead>
          <tr>
            <th className="text-center">Baca</th>
            <th>No. Resi</th>
            <th>Komentar</th>
          </tr>
        </thead>
        <tbody>
          {data.map((obj, i) => (
            <tr key={i}>
              <td className="text-center">
                <Button tag={Link} color="info" size="sm" title="Baca"
                  to={`${RouteBasic[1].URL}/${obj.ekspedisi.no_resi}`}
                >
                  <FontAwesomeIcon icon="eye" fixedWidth />
                </Button>
              </td>
              <td>
                <Badge pill color="light" className="px-3">{obj.ekspedisi.no_resi}</Badge>
              </td>
              <td>{obj.komentar}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  )
}

export default DashboardKomentarUnread
import React, { useContext, useEffect, useState } from 'react'
import { Button, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RNavLink, useLocation } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { RouteBasic, RouteUtils } from '../../Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppContext } from '../../App';
import { sortBy } from 'lodash';

const SharedSidebar = () => {
  const app = useContext(AppContext)

  return (
    <div className="sidebar-wrapper">
      <PerfectScrollbar>
        <p className="mb-2 ml-3">Basic</p>
        <Nav pills vertical>
          {sortBy(RouteBasic, ['ID'])
          .filter(x => x.IdLevel.includes(app.userLogin?.id_level) && x.JenisKantor.includes(app.userLogin?.kantor?.is_gerai))
          .map((obj, i) => {
            if (!!obj.Children) {
              return <SubMenuBuilder key={i} data={obj} />;
            }
            return (
              <NavItem key={i}>
                <NavLink tag={RNavLink} to={obj.URL}>
                  <FontAwesomeIcon icon="caret-right" fixedWidth className="mr-2" />{obj.Label}
                </NavLink>
              </NavItem>
            )
          })}
        </Nav>

        <hr/>

        <p className="mb-2 ml-3">Utilities</p>
        <Nav pills vertical>
          {RouteUtils.filter(x => x.IdLevel.includes(app.userLogin?.id_level)).map((obj, i) => (
            <NavItem key={i}>
              <NavLink tag={RNavLink} to={obj.URL}>
                <FontAwesomeIcon icon="caret-right" fixedWidth className="mr-2" />{obj.Label}
              </NavLink>
            </NavItem>
          ))}

          {app.userLogin.id_level === 1 && 
            <NavItem>
              <NavLink tag={RNavLink} to='/utils/broadcast'>
                <FontAwesomeIcon icon="caret-right" fixedWidth className="mr-2" />{`Broadcast`}
              </NavLink>
            </NavItem>
          }
        </Nav>
      </PerfectScrollbar>
    </div>
  )
}

const SubMenuBuilder = ({ data }) => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const isOpen = location.pathname.startsWith(data.URL);
    setIsOpen(isOpen);
  }, [location, data.url]);

  return (
    <NavItem>
      <Button className="nav-link bg-transparent text-left border-0" block onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={isOpen ? "caret-down" : "caret-right"} fixedWidth className="mr-2" />{data.Label}
      </Button>

      <Collapse isOpen={isOpen}>
        <Nav pills vertical className="ml-4">
          {data.Children?.map((obj, i) => (
            <NavItem key={i}>
              <NavLink tag={RNavLink} to={obj.URL}>
                <FontAwesomeIcon icon="dot-circle" size="sm" fixedWidth className="mr-2" />{obj.Label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Collapse>
    </NavItem>
  )
}

export default SharedSidebar;
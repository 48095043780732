import React, { useState, useRef, useEffect } from 'react'
import serviceApi, { Api, Lang } from '../../Config'
import { toast } from 'react-toastify'
import { Row, Col, Card, UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DashboardCounter = () => {

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const mounted = useRef(true)

  const fetchData = () => {
    setLoading(true)
    serviceApi.get(`${Api.Stats}/counter`)
      .then(res => {
        if (mounted.current) {
          setLoading(false)
          setData(res.data)
        }
      })
      .catch(err => {
        setLoading(false)
        toast.error(Lang.ErrorMsg)
        console.log(err)
      })
  }

  useEffect(() => fetchData(), [])

  if (loading) {
    return <div className="mb-3">Loading...</div>
  }

  return (
    <Row>
      {data?.map((obj, i) => (
        <Col sm={3} key={i}>
          <Card body color={obj.bgColor} className={`my-3 text-${obj.textColor}`} id={`cardDescription${i}`}>
            <p className="lead">{obj.title}</p>
            <div className="d-flex align-items-center justify-content-between">

              <FontAwesomeIcon icon={obj.icon} fixedWidth size="3x" />

              <h1 className="mb-0 font-weight-bold">{obj.count}</h1>
            </div>
          </Card>

          <UncontrolledTooltip placement="bottom" target={`cardDescription${i}`}>
            {obj.description}
          </UncontrolledTooltip>
        </Col>
      ))}
    </Row>
  )
}

export default DashboardCounter;
import React from 'react';

const SharedToastifyContent = (title, body) => {
  if (typeof body === 'object') {
    let content = [];
    for (const key in body) {
      const element = body[key][0];
      content.push(element);
    }
    return (
      <div>
        <h4><b>{title}</b></h4>
        <ul>
          {content.map((c, i) => (
            <li key={i}>{c}</li>
          ))}
        </ul>
      </div>
    )
  } else {
    return (
      <div>
        <h4><b>{title}</b></h4>
        <div>{body}</div>
      </div>
    )
  }
}

export default SharedToastifyContent;
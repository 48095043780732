import Axios from 'axios';

let urls = {
  test: process.env.REACT_APP_API_URL_DEVELOPMENT,
  development: process.env.REACT_APP_API_URL_DEVELOPMENT,
  production: process.env.REACT_APP_API_URL_PRODUCTION
}

let webUrls = {
  test: process.env.REACT_APP_WEBSITE_URL_DEVELOPMENT,
  development: process.env.REACT_APP_WEBSITE_URL_DEVELOPMENT,
  production: process.env.REACT_APP_WEBSITE_URL_PRODUCTION
}

export const Host = urls[process.env.NODE_ENV];
export const WebsiteURL = webUrls[process.env.NODE_ENV];

export const UploadPath = `${Host}/uploads`;
export const ImgPlaceholder = `${UploadPath}/placeholder.gif`;

export const Api = {
  Token: '3MKargoApiToken',
  Konfigurasi: `${Host}/konfigurasi`,
  Refer: `${Host}/refer`,
  Users: `${Host}/users`,
  Ekspedisi: `${Host}/ekspedisi`,
  EkspedisiBarang: `${Host}/ekspedisi-barang`,
  EkspedisiBukti: `${Host}/ekspedisi-bukti`,
  EkspedisiKomentar: `${Host}/ekspedisi-komentar`,
  EkspedisiPembayaran: `${Host}/ekspedisi-pembayaran`,
  EkspedisiArmada: `${Host}/ekspedisi-armada`,
  Kendaraan: `${Host}/kendaraan`,
  Member: `${Host}/member`,
  Vendor: `${Host}/vendor`,
  Pengeluaran: `${Host}/pengeluaran`,
  PengeluaranBukti: `${Host}/pengeluaran-bukti`,
  Website: `${Host}/website`,
  KamusBank: `${Host}/kamus-bank`,
  Kantor: `${Host}/kantor`,
  Stats: `${Host}/stats`,
  Laporan: `${Host}/laporan`
}

export const RouteBasic = [
  {ID: 1, URL: '/dashboard', Label: 'Dashboard', Icon: 'tachometer-alt', IdLevel: [1,2,3,4], JenisKantor: [0,1]},
  {ID: 3, URL: '/ekspedisi', Label: 'Manifest Data Entry', Icon: 'road', IdLevel: [1,2,3,4], JenisKantor: [0,1]},
  {ID: 5, URL: '/kendaraan', Label: 'Kendaraan', Icon: 'truck-moving', IdLevel: [1,2,3], JenisKantor: [0]},
  {ID: 2, URL: '/member', Label: 'Member', Icon: 'handshake', IdLevel: [1,2,3], JenisKantor: [0,1]},
  {ID: 6, URL: '/vendor', Label: 'Subcon', Icon: 'dove', IdLevel: [1,2,3], JenisKantor: [0]},
  {ID: 7, URL: '/pengeluaran', Label: 'Pengeluaran', Icon: 'money-check-alt', IdLevel: [1], JenisKantor: [0]},
  {ID: 8, URL: '/website', Label: 'Website', Icon: 'globe-asia', Children: [
    {URL: '/website/about-us', Label: 'About Us'},
    {URL: '/website/fitur', Label: 'Fitur'},
    {URL: '/website/layanan', Label: 'Layanan'},
    {URL: '/website/layanan-istimewa', Label: 'Layanan Istimewa'},
    {URL: '/website/slideshow', Label: 'Slideshow'},
    {URL: '/website/social-media', Label: 'Social Media'},
    {URL: '/website/keunggulan', Label: 'Video Youtube'},
    {URL: '/website/harga-pengiriman', Label: 'Harga Pengiriman'},
    {URL: '/website/news', Label: 'Berita'},
  ], IdLevel: [1], JenisKantor: [0]},
  {ID: 9, URL: '/laporan', Label: 'Laporan', Icon: 'clipboard-list', Children: [
    {URL: '/laporan/ekspedisi', Label: 'Ekspedisi'},
    {URL: '/laporan/pengeluaran', Label: 'Pengeluaran'},
    {URL: '/laporan/invoice', Label: 'Rekap Invoice'},
    {URL: '/laporan/profit-share', Label: 'Profit Share'},
  ], IdLevel: [1,2,3], JenisKantor: [1,0]},
  {ID: 4, URL: '/mde-customer', Label: 'MDE Customer', Icon: 'road', IdLevel: [1,2,3], JenisKantor: [0]},
];

export const RouteUtils = [
  {URL: '/utils/bank', Label: 'Bank', Icon: 'university', IdLevel: [1]},
  {URL: '/utils/kantor', Label: 'Kantor/Cabang', Icon: 'building', IdLevel: [1]},
  {URL: '/utils/kemitraan', Label: 'Registrasi Gerai', Icon: 'building', IdLevel: [1]},
];

export const Lang = {
  Add: {Label: 'Tambah', Color: 'success', Icon: 'plus-circle'},
  Edit: {Label: 'Edit', Color: 'warning', Icon: 'pencil-alt'},
  Update: {Label: 'Update', Color: 'primary', Icon: 'check-circle'},
  Save: {Label: 'Simpan', Color: 'primary', Icon: 'save'},
  Destroy: {Label: 'Hapus', Color: 'danger', Icon: 'trash'},
  Delete: {Label: 'Arsipkan', Color: 'danger', Icon: 'trash-alt'},
  Refresh: {Label: 'Refresh', Color: 'light', Icon: 'sync-alt'},
  Restore: {Label: 'Restore', Color: 'dark', Icon: 'undo'},
  Close: {Label: 'Tutup', Color: 'light', Icon: 'times-circle'},
  Print: {Label: 'Cetak', Color: 'secondary', Icon: 'print'},
  Detail: {Label: 'Detail', Color: 'info', Icon: 'eye'},
  Comment: {Label: 'Komentar', Color: 'info', Icon: 'comments'},
  Upload: {Label: 'Upload', Color: 'success', Icon: 'upload'},
  ErrorMsg: 'Something went wrong !',
  Required: 'Wajib diisi'
}

const serviceApi = Axios.create({
  baseURL: urls[process.env.NODE_ENV],
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

export default serviceApi;
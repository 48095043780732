import React, { useState, useContext } from 'react'
import { fakeAuth, AppContext } from '../../App';
import { useHistory, Redirect } from 'react-router-dom';
import { Card, CardHeader, Form, CardBody, CardFooter, Button, FormGroup, InputGroup, InputGroupAddon, Input, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import serviceApi, { Api, UploadPath, ImgPlaceholder, RouteBasic } from '../../Config';
import { toast } from 'react-toastify';
import SharedToastifyContent from '../../shared/SharedToastifyContent';

const Login = ({
  me = () => {}
}) => {
  let history = useHistory();

  let login = (userLogin) => {
    fakeAuth.authenticate(() => {
      if (userLogin.id_level === 4) {
        history.push(RouteBasic[1].URL);
      } else {
        history.push(RouteBasic[0].URL);
      }
    });
  };

  const app = useContext(AppContext)

  const [loading, setLoading] = useState(false)
  const [fields, setFields] = useState({
    username: '',
    password: ''
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    serviceApi.post(`${Api.Users}/login`, fields).then(res => {
      localStorage.setItem(Api.Token, JSON.stringify(res.data?.access_token));
      me();
      login(res.data?.user);
      setLoading(false);
    })
    .catch(err => {
      setLoading(false);
      toast.error(SharedToastifyContent('Failed !', err?.response?.data?.msg));
      console.log(err?.response?.data);
    });
  }

  if (fakeAuth.isAuthenticated) {
    return <Redirect to="/dashboard" />
  } else {
    return (
      <div className="login-wrapper">
        <Card>
          <CardHeader className="text-center font-weight-bold">LOGIN PAGE</CardHeader>
  
          <Form onSubmit={handleSubmit}>
            <CardBody>
              <div className="mb-3 text-center">
                <img src={app.konfigurasi.logo ? `${UploadPath}/${app.konfigurasi.logo_transparent}` : ImgPlaceholder} width={120} alt="logo"/>
              </div>

              <div className="mb-3 text-center">
                <h5 className="font-weight-bold">{app.konfigurasi.nama_perusahaan}</h5>
              </div>
  
              <hr/>
  
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon="user" fixedWidth />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input 
                    onChange={e => setFields({...fields, username: e.target.value})}
                    value={fields.username || ''}
                    autoFocus
                    placeholder="Username"
                  />
                </InputGroup>
              </FormGroup>
  
              <FormGroup className="mb-0">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon="lock" fixedWidth />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input 
                    type="password"
                    onChange={e => setFields({...fields, password: e.target.value})}
                    value={fields.password || ''}
                    placeholder="Password"
                  />
                </InputGroup>
              </FormGroup>
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary" block>
                {loading ? <FontAwesomeIcon icon="spinner" spin fixedWidth className="mr-2" /> : false}Login
              </Button>
            </CardFooter>
          </Form>
        </Card>
      </div>
    )
  }
}

export default Login;
import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import serviceApi, { Api, Lang } from '../../Config'
import { toast } from 'react-toastify'
import ApexCharts from 'react-apexcharts';
import { Spinner, Input, Label, FormGroup } from 'reactstrap'

const DashboardChartPengeluaranRekapPerMonth = () => {
  
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState({ tahun: moment().year() })
  const [options, setOptions] = useState({})
  const [series, setSeries] = useState([])

  const mounted = useRef(true)

  const fetchData = (tahun) => {
    setLoading(true)
    serviceApi.get(`${Api.Stats}/pengeluaran-rekap-per-month?tahun=${tahun}`)
      .then(res => {
        if (mounted.current) {
          setLoading(false)
          setFilter(prevState => ({...prevState, tahun: tahun}))

          const arrCategories = []
          const arrData = []
          res.data.forEach(obj => {
            arrCategories.push(obj.short);
            arrData.push(Number(obj.persentase));
          });

          setOptions({
            chart: {
              id: "basic-bar",
            },
            xaxis: {
              categories: arrCategories
            },
            yaxis: {
              labels: {
                show: true,
                formatter: function (val) {
                  return new Intl.NumberFormat('id-ID', {minimumFractionDigits: 0, maximumFractionDigits: 0}).format(val) + "%";
                }
              }
            }
          })

          setSeries([
            {
              name: "Persentase",
              data: arrData
            }
          ])
        }
      })
      .catch(err => {
        setLoading(false)
        toast.error(Lang.ErrorMsg)
        console.log(err)
      })
  }

  useEffect(() => {
    fetchData(moment().year());

    return () => {
      mounted.current = false;
    }
  }, [])

  if (loading) {
    return <Spinner color="primary" />
  }

  return (
    <React.Fragment>
      <FormGroup className="d-flex align-items-center">
        <Label className="mb-0 mr-3 nowrap">Tahun : </Label>
        <Input
          type="number"
          onChange={e => fetchData(Number(e.target.value))}
          value={filter.tahun || ''}
        />
      </FormGroup>
      
      <ApexCharts
        options={options}
        series={series}
        type="bar"
        width="100%"
      />
    </React.Fragment>
  )
}

export default DashboardChartPengeluaranRekapPerMonth;
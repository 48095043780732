import React, { useState, useEffect, Suspense, useContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import classNames from 'classnames'
import serviceApi, { Api, RouteBasic } from './Config';
import { ToastContainer, toast } from 'react-toastify';
import { Container } from 'reactstrap';
import WindowSizeListener from 'react-window-size-listener'
import { GoogleApiWrapper } from 'google-maps-react';
import Login from './components/auth/Login';
import SharedNavbar from './shared/layouts/SharedNavbar';
import SharedSidebar from './shared/layouts/SharedSidebar';
import Dashboard from './components/dashboard/Dashboard';
import SharedLoading from './shared/SharedLoading';
const Ekspedisi = React.lazy(() => import('./components/ekspedisi/Ekspedisi'))
const EkspedisiDetail = React.lazy(() => import('./components/ekspedisi/EkspedisiDetail'))
const Kendaraan = React.lazy(() => import('./components/kendaraan/Kendaraan'))
const KendaraanDetail = React.lazy(() => import('./components/kendaraan/KendaraanDetail'))
const Member = React.lazy(() => import('./components/member/Member'))
const MemberDetail = React.lazy(() => import('./components/member/MemberDetail'))
const Vendor = React.lazy(() => import('./components/vendor/Vendor'))
const VendorDetail = React.lazy(() => import('./components/vendor/VendorDetail'))
const Pengeluaran = React.lazy(() => import('./components/pengeluaran/Pengeluaran'))
const PengeluaranDetail = React.lazy(() => import('./components/pengeluaran/PengeluaranDetail'))
const Website = React.lazy(() => import('./components/website/Website'))
const Laporan = React.lazy(() => import('./components/laporan/Laporan'))
const Users = React.lazy(() => import('./components/users/Users'))
const Utils = React.lazy(() => import('./components/utils/Utils'))
const Konfigurasi = React.lazy(() => import('./components/konfigurasi/Konfigurasi'))
const AuthAccessDenied = React.lazy(() => import('./components/auth/AuthAccessDenied'))
const BookingPages = React.lazy(() => import('./components/mde-customer/Booking'))

library.add(fas, fab)

export const AppContext = React.createContext({
  konfigurasi: {
    nama_perusahaan: '',
    alamat: '',
    telp: '',
    email: '',
    website: '',
    logo: '',
    logo_transparent: '',
    logo_2: '',
    tagline: '',
    deskripsi: '',
    klausul: ''
  },
  refer: {
    jenisPengeluaran: [],
    jenisStatus: [],
    kamusBank: [],
    kantor: [],
    daftarKendaraan: [],
    daftarMember: [],
    daftarVendor: [],
    usersLevel: [],
    via: []
  },
  userLogin: {
    firstname: '',
    id_level: 1
  },
  toggleSidebar: () => {},
  fetchData: () => {}
});

export const useApp = () => React.useContext(AppContext);

function App() {
  const [loading, setLoading] = useState(true)
  const [konfigurasi, setKonfigurasi] = useState({})
  const [refer, setRefer] = useState({})
  const [userLogin, setUserLogin] = useState({
    firstname: '',
    id_level: 3
  })
  const [isHideSidebar, setIsHideSidebar] = useState(false)

  const toggleSidebar = () => setIsHideSidebar(!isHideSidebar)

  useEffect(() => {
    serviceApi.get(Api.Konfigurasi).then(res => {
      setKonfigurasi(res.data)

      if (fakeAuth.isAuthenticated) {
        me()
      } else {
        setLoading(false)
      }
    })
  }, []);

  const me = () => {
    serviceApi.defaults.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem(Api.Token))}`;
    serviceApi.get(`${Api.Users}/me`).then(res => {
      setUserLogin(res.data);

      serviceApi.get(Api.Refer).then(res => {
        setRefer(res.data);
        setLoading(false);
      }).catch(errRefer => setLoading(false));
    })
    .catch(errMe => {
      fakeAuth.signout(() => window.location.reload())
      setLoading(false)
      toast.error(errMe?.response?.data?.msg);
      console.log(errMe?.response?.data);
    });
  }

  if (loading) {
    return <SharedLoading/>;
  }

  return (
    <AppContext.Provider 
      value={{
        konfigurasi: konfigurasi,
        refer: refer,
        userLogin: userLogin,
        toggleSidebar: toggleSidebar,
        fetchData: () => me()
      }}
    >
      <BrowserRouter>
        <main className={classNames({'hide-sidebar': isHideSidebar})}>
          <ToastContainer position="top-center" />

          <AuthButton />

          <WindowSizeListener
            onResize={windowSize => {
              if (windowSize.windowWidth <= 768) {
                setIsHideSidebar(true);
              }
            }}
          />

          <div className="content-wrapper">
            <Container fluid>
              <Switch>
                <Route path="/login">
                  <Login me={() => me()} />
                </Route>

                <PrivateRoute path={RouteBasic[0].URL}>
                  <Dashboard />
                </PrivateRoute>

                <PrivateRoute exact path={RouteBasic[1].URL}>
                  <Suspense fallback="Loading...">
                    <Ekspedisi />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute path={`${RouteBasic[1].URL}/:noResi`}>
                  <Suspense fallback="Loading...">
                    <EkspedisiDetail />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute exact path="/kendaraan">
                  <Suspense fallback="Loading...">
                    <Kendaraan />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute path="/kendaraan/:id">
                  <Suspense fallback="Loading...">
                    <KendaraanDetail />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute exact path={RouteBasic[3].URL}>
                  <Suspense fallback="Loading...">
                    <Member />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute path={`${RouteBasic[3].URL}/:id`}>
                  <Suspense fallback="Loading...">
                    <MemberDetail />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute exact path="/vendor">
                  <Suspense fallback="Loading...">
                    <Vendor />
                  </Suspense>
                </PrivateRoute>
                <PrivateRoute path="/vendor/:id">
                  <Suspense fallback="Loading...">
                    <VendorDetail />
                  </Suspense>
                </PrivateRoute>

                <OwnerManagerRoute exact path={RouteBasic[5].URL}>
                  <Suspense fallback="Loading...">
                    <Pengeluaran />
                  </Suspense>
                </OwnerManagerRoute>
                <PrivateRoute path={`${RouteBasic[5].URL}/:id`}>
                  <Suspense fallback="Loading...">
                    <PengeluaranDetail />
                  </Suspense>
                </PrivateRoute>

                <OwnerRoute path={RouteBasic[6].URL}>
                  <Suspense fallback="Loading...">
                    <Website />
                  </Suspense>
                </OwnerRoute>

                <PrivateRoute path={RouteBasic[7].URL}>
                  <Suspense fallback="Loading...">
                    <Laporan />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute path="/mde-customer">
                  <Suspense fallback="Loading...">
                    <BookingPages />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute path="/utils">
                  <Suspense fallback="Loading...">
                    <Utils />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute path="/users">
                  <Suspense fallback="Loading...">
                    <Users />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute path="/konfigurasi">
                  <Suspense fallback="Loading...">
                    <Konfigurasi />
                  </Suspense>
                </PrivateRoute>

                <PrivateRoute path="/access-denied">
                  <Suspense fallback="Loading...">
                    <AuthAccessDenied />
                  </Suspense>
                </PrivateRoute>

                <Redirect to={userLogin.id_level === 4 ? RouteBasic[1].URL : RouteBasic[0].URL} />
              </Switch>
            </Container>
          </div>
        </main>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export const fakeAuth = {
  isAuthenticated: !!localStorage.getItem(Api.Token) ? true : false,
  authenticate(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    delete serviceApi.defaults.headers.Authorization;
    localStorage.removeItem(Api.Token);
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

const AuthButton = withRouter(({ history }) => (
  fakeAuth.isAuthenticated ? 
    <React.Fragment>
      <SharedNavbar history={history} />
      <SharedSidebar />
    </React.Fragment> : false
))

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        fakeAuth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export function OwnerRoute({ children, ...rest }) {
  const { userLogin } = useContext(AppContext)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        fakeAuth.isAuthenticated && (userLogin?.id_level === 1) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/access-denied",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export function OwnerManagerRoute({ children, ...rest }) {
  const { userLogin } = useContext(AppContext)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        fakeAuth.isAuthenticated && (userLogin?.id_level === 1 || userLogin?.id_level === 2) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/access-denied",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export function ExecutiveRoute({ children, ...rest }) {
  const app = useContext(AppContext)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        fakeAuth.isAuthenticated && (app.userLogin.id_level !== 4) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/access-denied",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export const DecimalFormat = (value = 0, digit = 2) => {
  return new Intl.NumberFormat('id-ID', {minimumFractionDigits: digit, maximumFractionDigits: digit}).format(value)
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY
})(App);
import React from 'react';

const SharedLoading = () => {
  return (
    <div className="loading-wrapper">
      {`LOADING`}
    </div>
  )
}

export default SharedLoading;